<template>
  <h2>Terms & Conditions</h2>
  <section class="termsContainer">
    <img :src="'/pdf/tyc/tyc (' + (x + 1) + ').jpg'" class="w-100" alt="Privacy" loading="lazy" v-for="x in 40" :key="x">
  </section>
</template>

<script>
  import TermCardVue from '../../components/global/TermCard.vue';
  export default {
    components: {TermCardVue}
  }
</script>

<style scoped>
  .termsContainer{
    display: flex;
    flex-wrap: wrap;
  }
</style>