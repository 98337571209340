<template>
  <h2>Privacy</h2>
  <section class="termsContainer">
    <img :src="'/pdf/privacy/privacy (' + (x + 1) + ').jpg'" class="w-100" alt="Privacy" loading="lazy" v-for="x in 9" :key="x">
  </section>
</template>

<script>
  import TermCardVue from '../../components/global/TermCard.vue';
  export default {
    components: {TermCardVue}
  }
</script>

<style scoped>
  .termsContainer{
    display: flex;
    flex-wrap: wrap;
  }
</style>